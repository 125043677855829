<template>
  <div></div>
</template>

<script>
export default {
  computed: {
    providerId() {
      return this.$route.params.providerId
    },
    sign() {
      // 'used' || 'food'
      return this.$route.query.sign
    },
    memberStores() {
      return this.$store.getters["member/memberStores"];
    },
    cooperationStatus() {
      return this.$store.getters[`store/cooperationStatus`]
    },
    isMainManager() {
      return this.$store.getters['member/isStoreMainManager']
    },
    hasCooperation() {
      return this.cooperationStatus == this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
    },
    hasRejected() {
      return this.cooperationStatus == this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
    },
  },
  async mounted() {
    await this.checkFlow();
  },
  methods: {
    async checkFlow() {
      // 入口: 供應商食材
      if(this.sign == 'food') {
        if(!this.hasCooperation) {
          this.$router.replace({name: 'store-product-list', params: {providerId: this.providerId}})
          return 
        }
        await this.bindRichMenuToCoManager()
        return
      }

      // 入口: 建立我的商家資訊 + 歡迎訊息的兩個按鈕
      // 點開我的商家資訊, 但審核未通過
      if(this.hasRejected) {
        this.$router.replace({name: 'store-reject', params: {providerId: this.providerId}})
        return
      }
      if (this.memberStores.length == 0) {
        if(this.sign == 'used') {
          // 發邀請訊息 todo
          window.liff.closeWindow();
          return
        }
        this.$router.replace({name: 'store-register', params: {providerId: this.providerId}})
        return
      }
      // 當store數量>=1時
      if (this.hasCooperation) {
        await this.bindRichMenuToCoManager()
        return
      }
      if(!this.isMainManager) {
        // 請主要管理員通知申請
        this.$router.replace({name: 'store-notify-invite', params: {providerId: this.providerId}})
        return
      }
      this.$router.replace({name: 'store-register', params: {providerId: this.providerId}})
    },
    async bindRichMenuToCoManager() {
      // 通知後端綁定rich_menu + 導頁去9.0.1
      this.$router.replace({name: 'store-product-list', params: {providerId: this.providerId }})
    },
  },
};
</script>